<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
/* 添加这段样式后，Primary Button 会变成红色 */
:root:root {
  --van-nav-bar-background: #1989fa;
  --van-nav-bar-title-text-color: #ffffff;
  --van-nav-bar-text-color: #ffffff;
  --van-nav-bar-icon-color: #ffffff;
}

</style>
