import { createApp } from 'vue'
import App from './App.vue'
import { Form, Field, CellGroup, Button, NavBar, Cell, Collapse, CollapseItem, NoticeBar, Notify, showNotify, Empty } from 'vant'
import 'vant/lib/index.css'
import router from './router'
const app = createApp(App)

app.use(router)

app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Button)
app.use(NavBar)
app.use(Cell)
app.use(CellGroup)
app.use(Collapse)
app.use(CollapseItem)
app.use(NoticeBar)
app.use(Notify)
app.use(showNotify)
app.use(Empty)
app.mount('#app')
