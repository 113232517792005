import * as VueRouter from 'vue-router'

const routes = [
    {
        path: '/',
        redirect:'/login',
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '桐尘预约',
        },
        component: () => import('../page/login/index.vue')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            title: '首页',
        },
        component: () => import('../page/home/index.vue')
    },
    {
        path: '/history',
        name: 'History',
        meta: {
            title: '历史收益',
        },
        component: () => import('../page/history/index.vue')
    },
];
const router = VueRouter.createRouter({
    routes,
    history: VueRouter.createWebHistory(),
    // history: VueRouter.createWebHashHistory(),
});
export default router